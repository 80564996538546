.sd-item--disabled {
  .sd-item__control-label {
    opacity: 1;
  }
}

.correctAnswer {
  color: green;
}

.incorrectAnswer {
  color: red;
}

.sd-timer.sd-timer--top {
  position: absolute !important;
  top: -24px;
  right: 0;

  @include media-breakpoint-down(md) {
    top: -30px;
    right: -15px;
  }
}

.sd-root-modern {
  border-radius: 8px;
}

.sd-container-modern {
  position: relative;
}

.sd-title.sd-container-modern__title {
  box-shadow: none;
  border-bottom: 1px solid rgb(35 101 200 / 13%);
  border-radius: 8px 8px 0 0;
  padding: 16px;

  .sd-title {
    padding-right: 82px;
    font-size: 28px;
    font-weight: 600;
  }

  @include media-breakpoint-down(md) {
    padding: 8px 16px;

    .sd-title {
      padding-right: 64px;
    }
  }
}

.sd-header__text .sd-title {
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.sd-element__title span,
.sd-item__control-label {
  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

.sd-row~.sd-row .sd-panel--as-page {
  @include media-breakpoint-down(md) {
    padding-top: 4px;
  }
}

.quiz-result {
  &-modal {
    z-index: 1056 !important;
  }

  &-backdrop {
    z-index: 1055 !important;
  }

  &-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 700px !important
    }
  }

  &-score {
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9;

    @include media-breakpoint-up(md) {
      top: 23px;
      font-size: 32px;
    }
  }
}

.sd-selectbase__label {
  width: 100%;
}

.sd-visuallyhidden.sd-item__control.sd-radio__control {
  position: fixed;
  right: -200vw;
}

.sd-item--disabled.sd-item--disabled .sd-item__decorator {
  background: rgb(232 240 253);
  box-shadow: none;
}

.sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
  background-color: rgb(152 175 208);
}

.sd-boolean {
  box-shadow: none;
}

.sd-boolean--disabled .sd-boolean__thumb,
.sd-boolean--disabled .sd-boolean__label {
  opacity: 1;
  color: rgba(0, 0, 0, 0.91);
  box-shadow: none;
}

.sd-question--disabled .sd-rating__item-text {
  opacity: 1;
}

.sd-ranking--disabled .sv-ranking-item__text,
.sv-ranking-item--disabled .sv-ranking-item__text {
  opacity: 1;
  color: rgba(0, 0, 0, 0.91);
  box-shadow: none;
}

.sd-input.sd-input--disabled {
  opacity: 1 !important;
  box-shadow: none;
}

.sd-imagepicker__check-decorator {
  width: 48px;
  height: 48px;

  &::before {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-family: "Font Awesome 6 Free";
    content: "\f00c";
    font-weight: 900;
    font-size: 24px;
    color: rgba(35, 101, 200, 1);
  }
}

.sd-imagepicker__image {
  padding: 1rem;
}