.tagify {
    @extend .form-control;
    height: auto;
    display: flex;
    gap: 0.5rem;


    --tag-bg: var(--primaryLight);
    --tag-pad: 0.3em 0.5em 0.3em 0.8em;

    &__tag {
        margin: 0;
        border-radius: 50px;
        overflow: hidden;

        &__removeBtn {
            margin-inline: auto 0.5rem;

            &::after {
                font-family: "Font Awesome 6 Free";
                font-weight: 900;
                content: "\f00d";
            }
        }

    }

    &__input {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }

    &--focus {
        width: 100% !important;
    }

}