@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

:root {
  --sidebarWidth: 345px;
  --miniSidebarWidth: 60px;
  --headerHeight: 70px;
  --primaryLight: #E1E9FC;
  --colorLight: #E8E8E8;
  --shadowIn: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px inset;
}

//bootstrap variable overrides
$primary: #3C5EAB;
$secondary: #31B1EA;
$success: #19b394;
$warning: #F4C546;
$danger: #FF384F;
$body-bg: #F7F7F7;
$input-bg: #FFFFFF;
$table-hover-bg: #fff;
$table-cell-padding-y: 15px;
$form-select-line-height: 28px;
$form-label-font-size: 1.125rem;
$form-label-font-weight: 500;
$input-line-height: 2.125;
$input-border-radius: 5px;
$input-border-color: #E4E4E4;
$input-focus-border-color: #333333;
$modal-content-bg: #F5F6FD;
$modal-content-border-radius: 12px;
$btn-border-radius: 45px;
$enable-negative-margins: true;
$card-border-color: #F5F6FD;
$box-shadow: 0px 4px 8px #C7D0E9;
$font-family-code: 'Poppins', sans-serif;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

@mixin ellipsis($before: true) {
  content: "...";
  font-size: 1.25rem;
  display: inline-block;

  @if($before) {
    margin-right: 2.5px;
  }

  @else {
    margin-left: 2.5px;
  }
}

html {
  font-size: var(--font-size) !important;
}

body {
  font-family: $font-family-code !important;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn:not([class*='btn-outline-']):active,
.btn:not([class*='btn-outline-']):active:focus-visible,
.btn:not([class*='btn-outline-']):focus,
.btn-close:focus {
  border-color: transparent !important;
  outline: none;
  box-shadow: none;
}

.btn.btn-primary-light {
  --bs-btn-color: var(--bs-primary) !important;
  --bs-btn-bg: var(--primaryLight) !important;
  --bs-btn-border-color: var(--primaryLight) !important;
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-hover-bg: #e6ecfc;
  --bs-btn-hover-border-color: #e4ebfc !important;
  --bs-btn-focus-shadow-rgb: 191, 198, 214;
  --bs-btn-active-color: var(--bs-primary);
  --bs-btn-active-bg: #e7edfd;
  --bs-btn-active-border-color: #e4ebfc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: var(--primaryLight);
  --bs-btn-disabled-border-color: var(--primaryLight);
}

.btn-outline-danger {

  &:hover,
  &:active,
  &.active {
    color: white !important;
  }
}

.btn-light-reset-hover:hover:not(:active) {
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}

.modal-dialog {
  max-width: 580px !important;
}

textarea {
  height: 80px;
  resize: none;
}

.card {
  --bs-card-bg: #ffffff !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);

  &.bg-primary-light {
    border: none;
    box-shadow: none;
  }
}

.dropdown-menu {
  --bs-dropdown-bg: #ffffff !important
}

.table {
  vertical-align: middle !important;
  --bs-table-bg: transparent !important;

  thead tr th {
    white-space: nowrap;
  }
}

.form-control {
  height: 48px;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

.badge {
  background-color: $primary;
  color: #fff;
}

.sticky-top {
  z-index: 998 !important;
}

.sticky-bottom,
.sticky-top {
  -webkit-position: sticky;
}

.flex-fill {
  min-width: 5px;
}

.flex-50 {
  flex: 50%
}

.bg-primary-light {
  background-color: var(--primaryLight) !important;
}

.bg-light-gray {
  background-color: #f3f3f3 !important;
}

.border-gray {
  --bs-border-color: rgba(0, 0, 0, 0.3);
}

.font-14 {
  font-size: 0.875;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-20 {
  font-size: 1.25rem;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.font-22 {
  font-size: 1.375rem;
}

.font-24 {
  font-size: 1.5rem;
}

.font-26 {
  font-size: 1.625rem;
}

.font-32 {
  font-size: 2rem;
}

.font-34 {
  font-size: 2.125rem;
}

.mw-120 {
  min-width: 120px;
}

.mw-160 {
  min-width: 160px;
}

.mw-200 {
  min-width: 200px;
}

.h-48 {
  height: 48px;
}

.size-32 {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
}

.size-30 {
  height: 1.875rem;
  width: 1.875rem;
  min-width: 1.875rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-38 {
  height: 2.375rem !important;
  width: 2.375rem !important;
  min-width: 2.375rem !important;
  padding: 0 !important;
}

.size-32-sm {
  @include media-breakpoint-down(sm) {
    height: 32px;
    width: 32px;
    min-width: 32px;
    padding: 0.25rem !important;
  }
}

.search-filter {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
  }
}

.logo {
  background: $primary;
  width: var(--sidebarWidth);
  height: var(--headerHeight);

  img {
    max-width: 45px;
    max-height: 45px;
  }

  @include media-breakpoint-down(lg) {
    min-width: var(--miniSidebarWidth);
    max-width: var(--miniSidebarWidth);
    background: #fff;
  }
}

.content-wrapper {
  margin-left: var(--sidebarWidth);
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }
}

#side-menu {
  position: fixed;
  left: 0px;
  top: var(--headerHeight);
  z-index: 999;
  width: 100%;
  padding: 0;
  min-width: var(--sidebarWidth);
  max-width: var(--sidebarWidth);
  height: calc(100vh - var(--headerHeight));
  z-index: 999;
  overflow: auto;

  .nav-link {
    color: #fff;
    font-size: 1.25rem;
    padding: 12px;
    white-space: nowrap;
  }

  @include media-breakpoint-down(lg) {
    left: calc(var(--sidebarWidth) * -1);
    transition: all 250ms ease-in;
  }
}

.mini-sidebar {
  #side-menu {
    .nav-link-label {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100vw !important;
      overflow-y: auto !important;
    }

    @include media-breakpoint-down(lg) {
      min-width: var(--sidebarWidth);
      max-width: var(--sidebarWidth);
      left: 0;
      overflow: auto;

      .nav-link-label {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      min-width: var(--miniSidebarWidth);
      max-width: var(--miniSidebarWidth);
    }
  }

  .content-wrapper {
    @include media-breakpoint-up(lg) {
      margin-left: var(--miniSidebarWidth);
    }
  }

  .logo {
    @include media-breakpoint-up(lg) {
      min-width: var(--miniSidebarWidth);
      max-width: var(--miniSidebarWidth);
    }
  }
}

.mini-logo {
  &.logo {
    @include media-breakpoint-up(lg) {
      min-width: var(--miniSidebarWidth);
      max-width: var(--miniSidebarWidth);
    }
  }
}

.meeting-card {
  .card {
    width: calc(50% - 1rem);
    border-radius: 45px;

    @media (min-width:1400px) {
      width: 310px;
    }
  }

  img {
    max-width: var(--headerHeight);
    object-fit: contain;
  }

  .count {
    font-size: 2.5rem;
    margin-bottom: 5px;
  }

  @include media-breakpoint-down(sm) {
    img {
      max-width: 40px;
    }

    .count {
      font-size: 1.25rem;
    }
  }
}

.custom-tab {
  .tab-content {
    flex: 1 1 auto;

    >.tab-pane {
      height: 100%
    }
  }

  .nav {
    background-color: var(--colorLight);
    margin-bottom: 12px;
    border-radius: 8px;
    position: relative;
    box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.13);
  }

  .nav-item {
    width: calc(100% / 3);

    .nav-link {
      width: 100%;
      padding: 0.7rem 1.5rem;
      color: #000;
      border-radius: 8px;

      &:not(.active):hover {
        background-color: #dddddd
      }

      @include media-breakpoint-down(sm) {
        padding: 0.7rem 0.5rem;
      }
    }
  }
}

.company-sponsor {
  display: flex;
  align-items: center;
  height: 80px;
  width: 80px;
  box-shadow: 0 0px 2px 0px rgb(35 0 0 / 70%);
  border-radius: 50%;
  object-fit: contain;
}

.section-tab {
  border-radius: 8px !important;
  overflow: hidden;
  background: var(--colorLight) !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.13);

  @include media-breakpoint-up(sm) {
    padding: 1rem;
  }
}

.conversation-list {
  position: absolute;
  top: 4rem;
  width: calc(100% - 2rem);
  height: calc(100% - 5rem - max(48px, calc(2.125em + 0.75rem + calc(var(--bs-border-width) * 2)))) !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1 1 auto;

  @include media-breakpoint-down(sm) {
    width: calc(100% - 1rem);
    height: calc(100% - 4rem - max(48px, calc(2.125em + 0.75rem + calc(var(--bs-border-width) * 2)))) !important;
    top: 3.5rem;
  }
}

.polls-card,
.quiz-card {
  position: absolute;
  width: calc(100% - 2rem);
  height: calc(100% - 5.375rem) !important;
  top: 4.375rem;
  overflow: auto;

  @include media-breakpoint-down(sm) {
    width: calc(100% - 1rem);
    height: calc(100% - 4.375rem) !important;
    top: 3.875rem;
  }
}

.question-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 auto;

  .question-item {
    border: 1px solid var(--primaryLight) !important;
    border-radius: 5px;
    background-color: #FFFFFF;
    overflow: hidden;
    position: relative;

    .btn-remove-question {
      right: 4rem;
    }

    .accordion-button {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      box-shadow: none;
      background-color: #f2f7ff;

      .question-item-header {
        padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
        display: flex;
        align-items: center;
        gap: 1rem;
        flex: 1 1 auto;
      }
    }
  }
}

.question-option {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 15px;
  counter-reset: item;
  margin-bottom: 0;

  li {
    position: relative;
    display: flex;
    align-items: center;
    min-width: calc(50% - 10px);

    &::before {
      content: counter(item, upper-alpha);
      counter-increment: item;
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 5px;
      color: $success;
      border: 1px solid $success;
      font-size: 0.75rem;
      border-radius: 50%;
      display: flex;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }

    &.correct-option {
      &::before {
        background: $success;
        color: #fff;
      }
    }
  }
}

.star-rating {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .on {
    color: #455da9;
  }

  .off {
    color: #ccc;
  }
}

.header-action-btns {
  .btn {
    @include media-breakpoint-down(md) {
      width: 2.25rem;
      height: 2.25rem;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#top-navbar {
  .dropdown-menu {
    &.show {
      z-index: 1002;
    }
  }
}

.validation-absolute {
  position: absolute;
  bottom: 0;
  left: 5px;
}

.scroll-bottom {
  position: absolute;
  right: 29px;
  bottom: 92px;
  border: 0;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  font-size: 1rem;
  color: #455da9 !important;
  background: #fff;
  z-index: 9;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 43%);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.password {
  padding-right: 2.5em !important;
}

.password-text {
  font-family: 'password';

  &::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif
  }
}

.password-toggle {
  position: relative;

  .password-toggle-btn {
    position: absolute;
    right: 10px;
    top: 13px;
    color: darkgray !important;

    &:hover {
      color: gray !important;
    }
  }
}

.meeting-code-tag {
  cursor: pointer;
  background-color: #1e2f55;
  color: #fff !important;
  padding: 2px 10px;
  border-radius: 7px;
}

.delete-confirm {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .delete-confirm-inner {
    width: 100%;
    max-width: 500px;
    background: #f5f6fd;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

    .delete-confirm-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 32px;
    }
  }
}

.profile-img-header {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.image-profile-page {
  max-width: 150px;
  max-height: 150px;
}

.guestlogin-wrapper {
  background: linear-gradient(180deg, $primary 0%, #1E2F56 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  min-height: calc(100vh - 74px);
  color: #fff;

  .guest-join-meeting {
    padding-top: 30px;
    background: url(../images/dashboard-img.svg) center bottom no-repeat;
    background-size: 80%;
  }
}

.guestlogin-topbar {
  background: linear-gradient(180deg, $primary 0%, #1E2F56 100%);
  box-shadow: 0px 1px 2px #00000029;
  padding: 12px;
}

.custom-pagination {
  button {
    min-width: 36px;
  }

  &.gap-0-5 {
    gap: 2px;
  }

  ul {
    list-style: none;
    display: flex;
    padding: 0;
    align-items: center;
    margin: 0;
    gap: 3px;

    li {
      @include media-breakpoint-down(md) {

        &:first-child,
        &.active,
        &.active-sibling:nth-last-child(2),
        // Show second to last child if the last one is active
        &:last-child {
          display: inline-block !important;
        }

        $how-many-on-ends: 3; // 1,2,3,...,10 || 1,...,8,9,10

        // There are >= 5 pages
        &:first-child:nth-last-child(n+6) {

          &~li {
            // Start out with all siblings hidden
            display: none;

            // Show the last children in the list by default
            &:nth-last-child(-n+#{$how-many-on-ends}) {
              display: inline-block;
            }

            // The child at the beginning of the last group shows ellipsis for the group
            &:nth-last-child(#{$how-many-on-ends}) {
              &:before {
                @include ellipsis(true);
              }
            }

            // The very beginning elements do not need to show ellipsis
            // The very end elements do not need to show ellipsis
          }

          &.active,
          &~li.active {

            // Show ellipsis before and after the active element
            &:before {
              @include ellipsis(true);
            }

            &:after {
              @include ellipsis(false);
            }

            // If the active element is in the first or last group, don't show ellipsis (siblings will take care of it)
            &:nth-child(-n+#{$how-many-on-ends - 1}),
            &:nth-last-child(-n+#{$how-many-on-ends - 1}) {

              &:before,
              &:after {
                display: none;
              }
            }

            // Hide the last group if "active" comes before them
            &~li:nth-last-child(-n+#{$how-many-on-ends}) {
              display: none;
            }

            // Show the first group together if "active" comes before them
            &~li:nth-child(-n+#{$how-many-on-ends}) {
              display: inline-block;
            }

            // If "active" is before the last member in the group, don't show ellipsis
            &~li:nth-child(-n+#{$how-many-on-ends - 1}) {
              &:after {
                display: none;
              }
            }


            // The child at the end of the first group shows ellipsis for the group
            &~li:nth-child(#{$how-many-on-ends}) {
              &:after {
                @include ellipsis(false);
              }
            }
          }
        }
      }

      @include media-breakpoint-up(md) {

        &:first-child,
        &.active-sibling,
        &.active,
        &.active+li,
        &:last-child {
          display: inline-block !important;
        }

        // There are >= 7 pages
        &:first-child:nth-last-child(n+8) {
          $how-many-on-ends: 5; // 1,2,3,4,5,...,10 || 1,...6,7,8,9,10

          &~li {
            // Start out with all siblings hidden
            display: none;

            // Show ellipsis before the previous one
            &.active-sibling:before {
              @include ellipsis(true);
            }

            // Show ellipsis after the next one
            &.active+li:after {
              @include ellipsis(false);
            }

            // Show the last children in the list by default
            &:nth-last-child(-n+#{$how-many-on-ends}) {
              display: inline-block;
            }

            // The child at the beginning of the last group shows ellipsis for the group
            &:nth-last-child(#{$how-many-on-ends}) {
              &:before {
                @include ellipsis(true);
              }
            }

            // The very beginning elements do not need to show ellipsis
            &:nth-child(-n+#{$how-many-on-ends - 3}),
            // The very end elements do not need to show ellipsis
            &:nth-last-child(-n+#{$how-many-on-ends - 3}),
            // Even if it's a sibling to "active"
            &.active-sibling:nth-last-child(-n+#{$how-many-on-ends - 1}) {

              &:before,
              &:after {
                display: none !important;
              }
            }
          }

          &.active,
          &~li.active {

            // Hide the last group if "active" comes before them
            &~li:nth-last-child(-n+#{$how-many-on-ends}) {
              display: none;

              // If there is overlap, the element will show, but hide it's ellipsis
              &:before {
                display: none;
              }
            }

            // Show the first group together if "active" comes before them
            &~li:nth-child(-n+#{$how-many-on-ends}) {
              display: inline-block;
            }

            // If "active" is before the last member in the group, don't show ellipsis
            &~li:nth-child(-n+#{$how-many-on-ends - 1}) {
              &:after {
                display: none;
              }
            }


            // The child at the end of the first group shows ellipsis for the group
            &~li:nth-child(#{$how-many-on-ends}) {
              &:after {
                @include ellipsis(false);
              }
            }

            // "active" should never show ellipsis
            &:before,
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.react-datepicker {
  &__header--time {
    height: 64px;
  }
}

.font-size-container {
  .btn {
    width: 25px;
    height: 25px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px !important;
  }
}

.meeting-questioncard {
  padding: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);

  @include media-breakpoint-down(md) {
    padding: 6px;
  }

  .meeting-user-badge {
    margin-left: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.meeting-host,
  .meeting-host {
    background: hsl(217.5deg 73% 86.29%) !important;

    .meeting-user-badge {
      position: relative;
      z-index: 1;

      &::after {
        position: absolute;
        content: "";
        width: calc(100% + 14px);
        height: calc(100% + 2px);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: rgb(255 255 255);
        border-radius: 50px;
        z-index: -1;
        border: 1px solid #00000030;
      }
    }
  }

  &.unread {
    background: rgba(255, 201, 122, 0.5);

    &.meeting-host,
    .meeting-host {
      background: rgba(255, 201, 122, 0.5) !important;
    }
  }

  .question-reply {
    padding: 8px;
    border-radius: 0.375rem;
    box-shadow: 0 0px 0px 1px rgba(0, 0, 0, 0.2);
  }
}

.custom-radio {
  position: relative;

  input[type="radio"] {
    appearance: none;
    position: absolute;

    &:checked {
      &+label {
        background: #000;

        span {
          color: #fff;
        }

        &:before {
          border: 4px solid #fff;
          background-color: $primary;
        }
      }
    }
  }

  label {
    position: relative;
    display: block;
    padding: 14px 18px;
    border-radius: 5px;
    cursor: pointer;
    background: #fff;

    @media (hover: hover) {
      &:hover {
        background: #000;

        .label-text {
          color: #fff;
        }
      }
    }

    .label-text {
      padding-left: 28px;
      color: #000;
    }

    &:before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid #ddd;
      background: #fff;
      transform: translateY(-50%);
      top: 50%;
      transition: all 250ms ease-in-out
    }
  }
}

.meet-actions-wrapper {
  position: fixed;
  z-index: 1000;

  .meet-actions {
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.6);
    transition: 250ms box-shadow cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      box-shadow: 0 2px 30px rgba(0, 0, 0, 0.7);
    }
  }
}

.drag-handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.dashboard-layout {
  background: url(../../src/images/dashboard-img.svg) center bottom no-repeat;
  background-size: contain;
}

.edited-label {
  font-size: 11px;
  color: #0e0e0e;
  position: absolute;
  bottom: -8px;
  right: 0;
}

.badge-notification {
  font-size: 14px !important;
  line-height: 0.9 !important;
  padding: 4px 8px !important;
}

.file-drop {
  padding: 15px;

  *> {
    pointer-events: none;
  }

  .droppable {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px dashed #A1A1A1;
    border-radius: 5px;
    position: relative;
    min-height: 50px;
  }
}

.signup-wrap {
  background: linear-gradient(90deg, #56ccf2, #56cbf293);
  min-height: 100vh;
}

.navbar {
  .nav-item {
    .nav-link {
      color: #000;
      font-size: 18px;
    }
  }
}

.txt-error {
  font-size: 14px;
}

.home-wrapper {
  .nav-link {
    color: #000;
    font-size: 18px;
  }
}

.intro {
  background: url(../images/banner-img.webp) no-repeat 0 0;
  background-size: cover;
  background-attachment: fixed;
  object-fit: contain;
  padding: calc(100px + (210 - 150) * ((100vw - 100px) / (1900 - 300))) 0;

  .title {
    font-size: 46px;
    font-weight: 500;
    color: #fff;

    @media (max-width:400px) {
      font-size: 40px;
    }
  }

  .subtitle {
    font-size: 20px;
    color: #fff;
  }
}

.sliderImage {
  width: 100%;
  object-fit: cover;
  max-height: 480px;
}

.footer {
  background: linear-gradient(to right, #1482a0, #1cb5e0);
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.waiting-user-details {
  font-size: 18px;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}

.selected-options-radio {
  cursor: pointer;
  transform: scale(1.5);
  margin-left: 4px;
}

.section-question-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .section-question-action-header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 1rem;
  }

  .section-question-block {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .section-question-header {
      display: flex;
      justify-content: space-between;
      align-items: end;
      gap: 1rem;
    }

    .section-question {
      background-color: rgba(231, 240, 255, 1);
      border-radius: 6px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);

      .section-option-block {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .section-option-img {
          object-fit: cover;
        }
      }
    }
  }
}

.poll-progress {
  background-color: #00000011 !important;
  margin-top: 6px;
}

.status-block {
  height: 300px;
  padding: 0.5rem;
  font-size: 24px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  &-nodata {
    background-color: hsl(0deg 0% 82%);
  }

  &-started {
    background-color: hsl(353.07deg 100% 90%)
  }

  &-submitted {
    background-color: hsl(182.16deg 48% 81%)
  }
}

.message-box {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 1.125rem;
  position: relative;
}

.tour-item {
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  @include media-breakpoint-down(md) {
    max-height: calc(100vh - 500px);
  }

  @media (max-width: 991px) and (max-height: 1180px) {
    max-height: calc(100vh - 500px);
  }

  @media (max-height: 576px) {
    max-height: 40vh;
  }
}

.rounded-custom {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important
}

.selected-options-score {
  width: 50px !important;
}

/* Heart beat animation */
.heartbeat {
  color: red;
  animation: beat .5s infinite alternate;
}

@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.custom-radio-btn {
  position: relative;

  input[type="radio"] {
    appearance: none;
    position: absolute;


    &:checked {
      &+label {
        background: $primary;
        color: #fff;
      }
    }
  }

  label {
    @extend .btn, .btn-primary-light;
  }
}

.shadow-in {
  box-shadow: var(--shadowIn);
}

.option {
  &-container {
    display: flex;
    background-color: #efefef;
    box-shadow: var(--shadowIn);
  }

  &-radiogroup,
  &-ranking,
  &-imagepicker {
    flex-direction: column;
    gap: 1rem;
    border-radius: 6px;
    padding: 12px;
  }

  &-boolean,
  &-rating {
    border-radius: 6em;
    position: relative;

    &-wrapper {
      input {
        &[type=radio] {
          appearance: none;
          position: absolute;
        }

        &[type=text] {
          border-radius: 50em;
          box-shadow: none;
          text-align: center;
        }
      }
    }
  }

  &-boolean {
    padding: 6px;
    width: 70%;
    margin: 0 auto;

    &::after {
      content: '';
      position: absolute;
      background-color: #fff;
      border-radius: 50em;
      transform: translateY(-50%);
      top: 50%;
      height: calc(100% - 12px);
      width: calc(50% - 7px);
      right: 6px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      transition: right 300ms ease-out;
    }

    &.isChecked {
      &:after {
        right: calc(50% + 1px);
      }
    }

    &>div {
      flex: 1 1 auto;
    }

    &-wrapper {
      position: relative;
      z-index: 1;

      input {
        &[type=radio] {
          &:checked {
            &+[type=text] {
              color: $success
            }
          }
        }

        &[type=text] {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }

  &-rating {
    padding: 18px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    transition: border-radius 500ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &-wrapper {
      input {
        &[type=radio] {
          &:checked {
            &+.rating-textbox {
              background-color: $success;
              color: #fff
            }
          }
        }
      }

      .rating-textbox {
        border-radius: 50em;
        box-shadow: none;
        text-align: center;
        min-width: 48px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.droparea {
  display: grid;
  place-items: center;
  padding: 1rem;
  margin: 0 1rem 2.5rem;
  width: calc(100% - 2rem);
  height: 12rem;
  border: 2px dashed rgb(215, 215, 215);
  border-radius: 6px;
  background-color: #fff;
  z-index: 9;
  position: -webkit-sticky;
  position: sticky;
  top: var(--headerHeight);

  &.active {
    background-color: gray;
    color: #fff
  }
}

.google-signin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #8c8c8c;
  color: rgb(94, 94, 94);
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 215px;
  font-weight: 500
}

.form-check-inline {
  display: flex !important;
  gap: 8px;
  align-items: center;
  margin: 0;

  .form-check-input {
    margin-top: 0;
    --bs-border-color: #c0c5ca
  }
}

@import '~bootstrap/scss/bootstrap.scss';